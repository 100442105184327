body {
    margin: 0;
    padding: 0;
    font-family: 'CircularStd', Roboto, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    background-color: transparent;
    color:inherit;
  }


ol, ul {
    /* list-style: none; */
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

.scroll {
    width: 200px;
    height: 400px;
     
    overflow: scroll;
 }
 .scroll::-webkit-scrollbar {
     width: 12px;
 }
 
 .scroll::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
     border-radius: 0px;
 }
 
 .scroll::-webkit-scrollbar-thumb {
     border-radius: 0px;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
 }

